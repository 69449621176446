type CustomLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

const CustomLabel = (props: CustomLabelProps) => {
  const { htmlFor, children, style, ...otherProps } = props
  return (
    <label htmlFor={htmlFor} style={{ marginLeft: 11, marginBottom: 11, color: '#00B578', ...style }} {...otherProps}>
      {children}
    </label>
  )
}

export default CustomLabel
