import styled from '@mui/styled-engine-sc'
import { TabsHeight } from '../constant'

export const Header = styled('header')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: calc(100vh - ${TabsHeight});
  background: #000;
  color: #fff;
`
