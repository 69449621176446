import { Divider, Stack } from '@mui/material'
import styled from '@mui/styled-engine-sc'
import TwitterImage from './images/twitter.png'
import DiscordImage from './images/discord.png'
import Logo from '../../images/logo.png'
import FlipGodFontImage from '../../images/FlipGod.png'

const FooterStyle = styled('footer')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 18.03125rem;
  padding: 2.09375rem 2.5625rem 0;
  background: #000;
  color: #fff;

  > p:first-of-type {
    font-family: Montserrat-Bold;
    font-size: 0.9375rem;
    font-weight: bold;
    line-height: 1.25rem;
    margin: 0;
  }

  img {
    width: 1.875rem;
    height: 1.875rem;
  }
`

interface FooterProps {
  style?: React.CSSProperties
}

const Footer: React.FC<FooterProps> = (props) => {
  const { style } = props
  return (
    <FooterStyle style={style}>
      <p>Join the community</p>
      <Stack direction="row" spacing={5} style={{ padding: '1.875rem 0 2.34375rem' }}>
        <a href="https://discord.gg/FsPf4ynPjK" target="_blank" rel="noreferrer">
          <img src={DiscordImage} alt="discord" />
        </a>
        <a href="https://twitter.com/FlipGod_xyz" target="_blank" rel="noreferrer">
          <img src={TwitterImage} alt="twitter" />
        </a>
      </Stack>
      <Divider style={{ borderColor: '#fff' }} flexItem light />
      <Stack direction="row" spacing={1} style={{ marginTop: '2.65625rem' }}>
        <img src={Logo} alt="logo" style={{ height: '2.25rem', width: '2.25rem' }} />
        <img src={FlipGodFontImage} alt="Flip God" style={{ height: '1.5625rem', width: '5.15625rem' }} />
      </Stack>
      <p style={{ fontFamily: 'Montserrat-Light', fontSize: '0.75rem', fontWeight: 300 }}>
        Flip your NFTs like an expert
      </p>
    </FooterStyle>
  )
}

export default Footer
