import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { Close } from '@mui/icons-material'
import { useAsyncFn } from 'react-use'
import { LoadingButton } from '@mui/lab'
import { useController, useForm } from 'react-hook-form'
import AppBar from '../AppBar'
import CustomLabel from '../CustomLabel'
import { useRequest, useUserInfo } from '../../hooks'
import DiamondImage from '../../images/diamond.png'
import { YellowButton } from '../../pages/UpgradeService/styles'
import { Apis } from '../../constant'

const useVerifyEmail = () => {
  const request = useRequest()
  return useAsyncFn(
    async (pageId: 1 | 2, email: string) => {
      return request(Apis.VerifyEmail, {
        method: 'post',
        body: JSON.stringify({
          pageId: 2,
          email,
        }),
      })
    },
    [request]
  )
}

// 未发送验证邮件、已发送验证邮件、错误的邮箱、验证失败、验证成功
type Step = 'not-send' | 'sent' | 'wrong-email' | 'verify-fail' | 'verified'

type FieldValues = {
  email: string
}

export type EmailDialogRef = {
  open: () => void
  close: () => void
}

type EmailDialogProps = {
  onVerified?: () => void
  /**
   * 如果是从 settings 页面打开的，则传 2，如果是从 My Subscriptions 页面打开的，则传 1
   */
  pageId: 1 | 2
}

// TODO 这个组件需要重构，逻辑太乱了
const EmailDialog = forwardRef<EmailDialogRef, EmailDialogProps>((props, ref) => {
  const { onVerified, pageId } = props
  const { userInfo, refetch, refetching } = useUserInfo()

  const [step, setStep] = useState<Step>('not-send')
  const [{ loading: verifying, value }, verifyEmail] = useVerifyEmail()
  const { handleSubmit, control } = useForm<FieldValues>()
  const {
    field: { ref: inputRef, onChange, ...otherProps },
    fieldState,
  } = useController<FieldValues>({
    control,
    name: 'email',
    defaultValue: userInfo?.emailStatus ? userInfo.email : '',
    rules: {
      required: {
        value: true,
        message: 'Please input your email address',
      },
      // @see https://developer.mozilla.org/zh-CN/docs/Web/HTML/Element/input/email#basic_validation
      pattern: {
        value:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        message: 'Please enter the correct email address',
      },
    },
  })

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    []
  )

  const handleVerifyEmail = ({ email }: FieldValues) => {
    verifyEmail(pageId, email).then((response) => {
      if (response.code === 200) {
        setStep('verified')
      } else {
        setStep('wrong-email')
      }
    })
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
      <AppBar
        title="Set New Email"
        leftAction={null}
        rightAction={
          <IconButton aria-label="return" color="inherit" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        }
      />
      <Box sx={{ p: '26px 22px 167px' }}>
        <Box
          sx={{
            border: '1px dashed rgba(0, 0, 0, 0.16)',
            p: '22px',
            display: 'flex',
            flexFlow: 'row nowrap',
            columnGap: '17px',
            mb: '28px',
          }}
        >
          <img src={DiamondImage} alt="diamond" width={30} />
          <Typography
            sx={{
              fontFamily: 'Montserrat-Medium',
              fontSize: '13px',
              fontWeight: 500,
              color: '#07B9B9',
              wordBreak: 'break-all',
            }}
          >
            {userInfo?.address}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(handleVerifyEmail)}>
          <FormControl hiddenLabel fullWidth error={Boolean(fieldState.error) || step === 'wrong-email'}>
            <CustomLabel htmlFor="email-address" style={{ color: '#000', marginLeft: 0 }}>
              New Email Address
            </CustomLabel>
            <OutlinedInput
              fullWidth
              type="email"
              inputRef={inputRef}
              id="email-address"
              inputProps={{ 'aria-label': 'email address' }}
              disabled={step === 'verified' || step === 'sent'}
              onChange={(event) => {
                if (step === 'wrong-email') {
                  setStep('not-send')
                }
                onChange(event)
              }}
              {...otherProps}
            />
            {(step === 'verified' || step === 'verify-fail') && (
              <FormHelperText sx={{ ml: 0 }}>
                The comfirmed email has been sent to your email address.
                <br />
                <br />
                If you do not receive the email within a few minutes, please check your spam folder, click on the
                &quot;not spam&quot; button and move it to your inbox.
              </FormHelperText>
            )}
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            {step === 'wrong-email' && value && <FormHelperText>{value.message}</FormHelperText>}
          </FormControl>
          {step === 'not-send' || step === 'sent' || step === 'wrong-email' ? (
            <LoadingButton
              loading={verifying}
              sx={{ m: '50px auto 20px', display: 'block', pl: '57px', pr: '57px', fontSize: '16px' }}
              variant="contained"
              type="submit"
            >
              Verify
            </LoadingButton>
          ) : (
            <YellowButton
              loading={refetching}
              sx={{ color: '#fff', m: '50px auto 20px', display: 'block' }}
              onClick={() => {
                refetch().then((response) => {
                  if (response.emailUpdateStatus) {
                    onVerified?.()
                    setOpen(false)
                  } else {
                    setStep('verify-fail')
                  }
                })
              }}
            >
              Verification Complete
            </YellowButton>
          )}
        </form>
        {step === 'verify-fail' && (
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'Montserrat-Regular',
              color: '#FA5151',
              textAlign: 'center',
              width: '240px',
              margin: '0 auto',
            }}
          >
            You haven&apos;t finished email verification.
            <br />
            Please go and check your email to click on the link to verify.
          </Typography>
        )}
      </Box>
    </Dialog>
  )
})

EmailDialog.displayName = 'EmailDialog'

export default EmailDialog
