import { useCallback } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { getAddress, parseEther } from 'ethers/lib.esm/utils'
import { useSnackbar } from 'notistack'
import useEthereumProvider from './useEthereumProvider'

const useMetaMask = () => {
  const provider = useEthereumProvider()
  const { enqueueSnackbar } = useSnackbar()
  const notInstalled = !provider

  const requestAccounts = useCallback(async () => {
    if (notInstalled) return ''

    const accounts = (await provider.request({ method: 'eth_requestAccounts' })) as string[] | null
    const address = accounts ? getAddress(accounts[0]) : ''

    return address
  }, [notInstalled, provider])

  const requestPersonalSign = useCallback(
    async (address: string, message: string) => {
      if (notInstalled) return ''

      // https://docs.metamask.io/guide/signing-data.html#a-brief-history
      return provider.request({
        method: 'personal_sign',
        params: [address, message],
      }) as Promise<string>
    },
    [notInstalled, provider]
  )

  const isMainNetwork = useCallback(async () => {
    if (notInstalled) throw new Error('MetaMask not installed')

    const networkId = await provider.request<string>({ method: 'net_version' })

    return networkId === '1'
  }, [notInstalled, provider])

  const sendTransaction = useCallback(
    async (price: number) => {
      if (notInstalled) throw new Error('MetaMask not installed')

      if (!(await isMainNetwork()) && process.env.NODE_ENV !== 'development') {
        const message = 'FlipGod is currently only available on Ethereum. Change networks to continue.'
        enqueueSnackbar(message, { variant: 'warning' })
        throw new Error(message)
      }

      const address = await requestAccounts()

      const txId = (await provider.request<string>({
        method: 'eth_sendTransaction',
        params: [
          {
            from: address,
            to: process.env.REACT_APP_TO,
            value: parseEther(price.toString(10)).toHexString(),
          },
        ],
      })) as string

      return [txId, address] as const
    },
    [notInstalled, isMainNetwork, requestAccounts, provider, enqueueSnackbar]
  )

  const jumpToApp = useCallback(() => {
    // TODO: Test
    if (isAndroid) {
      window.location.replace('intent://metamask.io/#Intent;scheme=https;package=com.metamask.android;end')
    } else if (isIOS) {
      window.location.replace('metamask://')
    }
  }, [])

  return {
    requestAccounts,
    requestPersonalSign,
    jumpToApp,
    notInstalled,
    sendTransaction,
  }
}

export default useMetaMask
