/* eslint-disable @typescript-eslint/no-explicit-any */

const retry = <Fn extends (...args: any[]) => Promise<any>>(time: number, fn: Fn, ...args: Parameters<Fn>) => {
  const retryCore = (): any => {
    if (time-- <= 0) return Promise.reject()

    return fn(...args).catch(() => retryCore())
  }

  return retryCore() as ReturnType<Fn>
}

export default retry
