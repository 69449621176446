import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import copy from 'copy-to-clipboard'

interface CopyToClipboardButtonProps {
  content: string
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = (props) => {
  const { content } = props
  const { enqueueSnackbar } = useSnackbar()

  const handleClick = () => {
    copy(content, {
      debug: process.env.NODE_ENV === 'development',
    })
    enqueueSnackbar('Copied to clipboard')
  }

  return (
    <Button onClick={handleClick} variant="outlined">
      Copy This Website URL
    </Button>
  )
}

export default CopyToClipboardButton
