import { noop, omit } from 'lodash-es'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { Apis, TokenLocalStorageKey, UserLocalStorageKey } from '../constant'
import useRequest from '../hooks/useRequest'
import { GetUserDetailResponse, UserInfo } from '../types'
import { TokenContextProvider } from './TokenContext'

export type UserInfoContextType = {
  userInfo: ReturnType<typeof useLocalStorage<UserInfo>>[0]
  setUserInfo: ReturnType<typeof useLocalStorage<UserInfo>>[1]
  cleanUserInfo: ReturnType<typeof useLocalStorage<UserInfo>>[2]
  refetch: () => Promise<GetUserDetailResponse>
  refetching: boolean
}

export const UserInfoContext = React.createContext<UserInfoContextType>({
  userInfo: undefined,
  setUserInfo: noop,
  cleanUserInfo: noop,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  refetch: noop,
  refetching: false,
})

export const UserContextProvider = (props: { children: React.ReactNode }) => {
  const { children } = props

  const [userInfo, setUserInfo, cleanUserInfo] = useLocalStorage<UserInfo>(UserLocalStorageKey)
  const [token] = useLocalStorage<string>(TokenLocalStorageKey)
  const request = useRequest()
  const [refetching, setRefetching] = useState(false)

  const fetchUserInfo = useCallback(() => {
    setRefetching(true)
    return request<GetUserDetailResponse>(Apis.GetUserDetail)
      .then((response) => {
        if (response.code === 200) {
          setUserInfo(omit(response, ['code', 'message']))
        }
        return response
      })
      .finally(() => setRefetching(false))
  }, [request, setUserInfo])

  useLayoutEffect(() => {
    if (token) {
      fetchUserInfo()
    }
  }, [fetchUserInfo, token])

  const providerValue = useMemo<UserInfoContextType>(
    () => ({
      userInfo,
      setUserInfo,
      cleanUserInfo,
      refetch: fetchUserInfo,
      refetching,
    }),
    [cleanUserInfo, fetchUserInfo, setUserInfo, userInfo, refetching]
  )

  return (
    <UserInfoContext.Provider value={providerValue}>
      <TokenContextProvider>{children}</TokenContextProvider>
    </UserInfoContext.Provider>
  )
}
