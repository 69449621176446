import { AppBar, Footer } from '../../components'

const Settings = () => {
  return (
    <div>
      <AppBar title="Settings" />
      <Footer />
    </div>
  )
}

export default Settings
