import { styled, SxProps, Theme } from '@mui/material'
import { useUserInfo } from '../../hooks'
import { Role } from '../../types'
import ImperialCrownImage from '../../images/imperial-crown.svg'

const ImperialCrownStyle = styled('img')({
  position: 'absolute',
  transform: 'scale(1.6) translate(-3px, -3px)',
})

const ImperialCrown = (props: { size?: string; sx?: SxProps<Theme> }) => {
  const { size = '30px', sx } = props
  const { userInfo } = useUserInfo()
  const isFreeUser = userInfo?.role && userInfo.role === Role.ROLE_USER

  return isFreeUser ? (
    <ImperialCrownStyle src={ImperialCrownImage} alt="imperial crown" sx={{ width: size, height: size, ...sx }} />
  ) : null
}

export default ImperialCrown
