import React, { useState } from 'react'
import styled from '@mui/styled-engine-sc'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import Tab from '@mui/base/TabUnstyled'
import tabUnstyledClasses from '@mui/base/TabUnstyled/tabUnstyledClasses'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { UserTab } from './components'
import { TabsHeight } from '../../constant'

const TabsStyle = styled(TabsUnstyled)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  column-gap: 2.3125rem;
  padding: 0 1.875rem;
  height: ${TabsHeight};
  width: 100%;
  font-size: 0.9375rem;
  color: #fff;
  box-sizing: border-box;
  background: #000;

  > a {
    color: currentColor;
    text-decoration: none;
  }

  .${tabUnstyledClasses.selected} {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 120%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 0.75rem;
      height: 0.25rem;
      background: #fff;
      border-radius: 0.3125rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #000;
    top: calc(100% - 1px);
    left: 0;
  }
`

// https://github.com/mui/material-ui/issues/32882
// eslint-disable-next-line @typescript-eslint/ban-types
const OmitOwnerStateHOC = <T, P = {}>(Component: React.ComponentType<P>) => {
  return React.forwardRef<T, P>((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ownerState, ...other } = props
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...other} ref={ref} />
  })
}

const Tabs = () => {
  const [value, setValue] = useState<'home' | 'tools'>('home')

  return (
    <TabsStyle aria-label="nav tabs" value={value}>
      <UserTab />
      <Tab
        onClick={() => setValue('home')}
        component={OmitOwnerStateHOC<HTMLAnchorElement, NavLinkProps>(NavLink)}
        to="/"
        value="home"
      >
        Home
      </Tab>
      <Tab
        onClick={() => setValue('tools')}
        component={OmitOwnerStateHOC<HTMLAnchorElement, NavLinkProps>(NavLink)}
        to="/tools"
        value="tools"
      >
        Tools
      </Tab>
    </TabsStyle>
  )
}

export default Tabs
