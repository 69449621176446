import { Box, IconButton, Stack } from '@mui/material'
import { ArrowBackIosOutlined as ArrowBackIosOutlinedIcon } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { isUndefined } from 'lodash-es'
import { useEffect, useRef } from 'react'
import { AppBar, EmailDialog } from '../../components'
import { CollectionSelect, Marketplaces, MinMaxInput, PriceInput, PropertySelect, TraitSelect } from './components'
import { useAddMonitor, useCollectionList, usePropertyList, useTraitList } from './hooks'
import { useUserInfo } from '../../hooks'
import { EmailDialogRef } from '../../components/EmailDialog/EmailDialog'

export enum FormField {
  Collection = 'collection',
  Property = 'property',
  Trait = 'trait',
  Marketplaces = 'marketplaces',
  Min = 'min',
  Max = 'max',
  Price = 'price',
}

export type FormFieldValues = {
  [FormField.Collection]: number
  [FormField.Property]: string
  [FormField.Trait]: string
  [FormField.Marketplaces]: string[]
  [FormField.Min]: string
  [FormField.Max]: string
  [FormField.Price]: string
}

const SetNewAlerts = () => {
  const { data: collectionList, loading: collectionListLoading } = useCollectionList()
  const formMethods = useForm<FormFieldValues>({
    defaultValues: {
      // An empty string is required as the default value, otherwise React will report an "A component is changing an uncontrolled input to be controlled" error
      [FormField.Collection]: '' as unknown as number,
      [FormField.Property]: '',
      [FormField.Trait]: '',
      [FormField.Price]: '',
      [FormField.Min]: '',
      [FormField.Max]: '',
    },
  })
  const { control, handleSubmit, reset } = formMethods
  const { userInfo } = useUserInfo()
  const changedValues = useWatch<FormFieldValues>({ name: [FormField.Collection, FormField.Property], control })
  const [collectionId, propertyName] = changedValues
  const { enqueueSnackbar } = useSnackbar()
  const [addingMonitorState, addMonitor] = useAddMonitor()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  const { data: propertyList, loading: propertyListLoading } = usePropertyList(collectionId)
  const traitList = useTraitList(propertyName, propertyList)
  const selectedProperty = propertyList.find((property) => property.name === propertyName)
  const showMaxMinField = selectedProperty?.type === 1

  let timer: number | null = null
  useEffect(
    () => () => {
      if (timer) {
        clearTimeout(timer)
      }
    },
    [timer]
  )

  const emailDialogRef = useRef<EmailDialogRef>(null)

  const onSubmit = handleSubmit((data) => {
    if (!userInfo?.emailStatus) {
      emailDialogRef.current?.open()
      return
    }

    addMonitor({
      collectionId: data[FormField.Collection],
      marketList: data[FormField.Marketplaces],
      price: Number(data[FormField.Price]),
      propertyList: [
        {
          traitType: selectedProperty?.type as number,
          traitValue: data[FormField.Trait],
          min: isUndefined(data[FormField.Min]) ? undefined : Number(data[FormField.Min]),
          max: data[FormField.Max] ? undefined : Number(data[FormField.Max]),
        },
      ],
    }).then((response) => {
      if (response.code === 200) {
        enqueueSnackbar('Successfully Applied')
        reset()
      } else if (response.code === 402) {
        enqueueSnackbar(
          'Free trial member can only fully enjoy one feature. Become our paid subscriber to enjoy upto 100 NFT flipping features!'
        )
        timer = setTimeout(() => {
          navigate('/upgrade-service')
        }, 1000) as unknown as number
      }
    })
  })

  return (
    <Box>
      <AppBar
        title="Set New Alert"
        leftAction={
          <IconButton aria-label="return" color="inherit" component={Link} to="../">
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        }
      />
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <Stack
            direction="column"
            rowGap="39px"
            sx={{
              padding: '18px 12.5px',
              bgcolor: '#F1F1F1',
            }}
          >
            <CollectionSelect collectionList={collectionList} collectionListLoading={collectionListLoading} />

            <PropertySelect propertyList={propertyList} propertyListLoading={propertyListLoading} />
            {showMaxMinField ? (
              <MinMaxInput traitList={traitList} />
            ) : (
              <TraitSelect
                traitList={traitList}
                propertyListLoading={propertyListLoading}
                propertyName={propertyName}
              />
            )}

            <Marketplaces />

            <PriceInput />

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: '50px', mb: '200px' }}
              loading={addingMonitorState.loading}
            >
              Apply
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      <EmailDialog ref={emailDialogRef} pageId={1} />
    </Box>
  )
}

export default SetNewAlerts
