import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEmailAlertHistory } from '../../hooks'

const hiddenTableHeadForA11y = {
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
}

const tableRowSx = { '&:last-child td, &:last-child th': { border: 0 } }

const TableData = () => {
  const { data: emailAlertHistory, loading } = useEmailAlertHistory()

  return (
    <>
      <Divider sx={{ mt: '13px' }} />
      <TableContainer sx={{ maxHeight: '300px', minHeight: '60px' }}>
        <Table aria-labelledby="email-alert-history">
          <TableHead sx={hiddenTableHeadForA11y}>
            <TableRow>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="right">collection Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || emailAlertHistory.length <= 0 ? (
              <TableRow sx={tableRowSx}>
                <TableCell colSpan={2}>
                  {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Typography textAlign="center" lineHeight="40px" fontSize="12px" color="text.secondary">
                      No records to dispay
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ) : (
              emailAlertHistory.map((row) => (
                <TableRow key={row.id} sx={tableRowSx}>
                  <TableCell align="left">{new Date(row.createAt).toLocaleString()}</TableCell>
                  <TableCell align="right">{row.collectionName}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const AlertHistory = () => {
  return (
    <Paper sx={{ borderRadius: '10px', p: '17px 16.5px' }} elevation={0}>
      <Typography id="email-alert-history" variant="h2" fontSize="12px" color="#000" fontWeight={400} lineHeight="14px">
        Email Alert History
      </Typography>
      <TableData />
    </Paper>
  )
}

export default AlertHistory
