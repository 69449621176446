import { FormControl, OutlinedInput, InputAdornment, FormHelperText } from '@mui/material'
import { isNil } from 'lodash-es'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import CustomLabel from '../../../../components/CustomLabel'

const PriceInput = () => {
  const { control } = useFormContext<FormFieldValues>()
  const [collectionId, traitName] = useWatch({ control, name: [FormField.Collection, FormField.Trait] })
  const {
    field: { ref, ...otherFieldProps },
    fieldState: { error },
  } = useController({
    name: FormField.Price,
    control,
    rules: {
      required: {
        value: true,
        message: 'Please enter your price-cap',
      },
    },
  })

  const getPlaceholder = () => {
    const base = 10
    if (isNil(collectionId)) return undefined
    if (isNil(traitName)) return `Floor Price ${base} ETH`
    return `Trait Floor Price ${base} ETH`
  }

  return (
    <FormControl fullWidth hiddenLabel error={Boolean(error)}>
      <CustomLabel htmlFor="price-input">Price-cap regulation≤:</CustomLabel>
      <OutlinedInput
        id="price-input"
        endAdornment={<InputAdornment position="end">ETH</InputAdornment>}
        sx={{ bgcolor: '#fff' }}
        type="number"
        placeholder={getPlaceholder()}
        inputProps={{
          'aria-label': 'Price-cap regulation <=',
          'aria-describedby': error && 'price-helper-text',
        }}
        inputRef={ref}
        {...otherFieldProps}
      />
      {error && <FormHelperText id="price-helper-text">{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default PriceInput
