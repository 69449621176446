import { Box, Typography, Button } from '@mui/material'
import styled from '@mui/styled-engine-sc'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../styles/layout'
import SwiperSlideImage1 from './images/swiper-image-1.png'
import SwiperSlideImage2 from './images/swiper-image-2.png'
import CardImage1 from './images/card1.png'
import CardImage2 from './images/card2.png'
import './style.css'
import { useUserInfo, useWalletDrawer } from '../../hooks'

const ContentBox = styled(Box)`
  padding: 1rem 2.5rem 1.9375rem;

  > p {
    font-family: Montserrat;
    line-height: 1.875rem;
    margin-bottom: 0.8125rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ImageBox = styled(Box)`
  position: relative;
  background: #000;
  border-top: 1px solid #000;
  padding-top: 4rem;

  & > img[data-name='card1'] {
    position: absolute;
    width: 15.5rem;
    height: 15.5rem;
    top: 0;
    left: -3rem;
  }

  & > img[data-name='card2'] {
    display: block;
    width: 92.266667%;
    margin: 0 auto;
  }
`

const Tools = () => {
  const walletDrawer = useWalletDrawer()
  const { userInfo } = useUserInfo()
  const navigate = useNavigate()
  const handleClickSubscribe = () => {
    if (userInfo) {
      navigate('/my-subscriptions')
      return
    }

    if (walletDrawer.current) {
      walletDrawer.current.open()
    }
  }
  return (
    <>
      <Header style={{ borderBottom: '1px solid #000' }}>
        <Typography
          component="h1"
          variant="body1"
          style={{
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 600,
            lineHeight: '1.4375rem',
            margin: '1.6875rem 0 1.34375rem',
          }}
        >
          Great Deal Alert
        </Typography>
        <div style={{ width: '100%' }}>
          <Swiper modules={[Pagination]} pagination={{ clickable: true }} style={{ height: '17rem' }}>
            <SwiperSlide>
              <img style={{ width: '220px', height: '220px' }} src={SwiperSlideImage1} alt="swiper 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img style={{ width: '200px', height: '200px' }} src={SwiperSlideImage2} alt="swiper 2" />
            </SwiperSlide>
          </Swiper>
        </div>
        <ContentBox>
          <Typography variant="body2">
            1. Customize your great deal alert to track NFT collection with any trait you want
          </Typography>
          <Typography variant="body2">2. Email alert will be sent promptly</Typography>
        </ContentBox>
        <Button
          variant="contained"
          onClick={handleClickSubscribe}
          style={{
            width: '12.5rem',
            height: '2.5rem',
            fontFamily: 'Montserrat-Medium',
            fontWeight: 500,
            fontSize: '16px',
            marginBottom: 'auto',
          }}
        >
          Subscribe
        </Button>
        <KeyboardDoubleArrowDownIcon style={{ marginBottom: '1rem' }} />
      </Header>
      <ImageBox>
        <img data-name="card1" src={CardImage1} alt="card" />
        <img data-name="card2" src={CardImage2} alt="card" />
      </ImageBox>
    </>
  )
}

export default Tools
