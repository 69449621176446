import { Button, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { CircularProgressWithLabel } from '..'
import { useUserInfo } from '../../../../hooks'
import { Role } from '../../../../types'
import { useDayNumber, useEmailRemindStatistics } from '../../hooks'

const AlertViews = () => {
  const { userInfo } = useUserInfo()
  const isFreeUser = userInfo?.role === Role.ROLE_USER
  const { registeredDay, restDay, totalDay } = useDayNumber()
  const { todayTimes, totalTimes } = useEmailRemindStatistics()
  return (
    <Paper sx={{ borderRadius: '10px' }} elevation={0}>
      <Stack columnGap={2} direction="row" alignItems="center" justifyContent="space-between" p="12.5px 15px">
        <Stack
          flex={1}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          rowGap={1.5}
          minHeight="145px"
        >
          <Typography textAlign="center" fontSize="12px" color="#000" p="0 25px" lineHeight={1.2}>
            {isFreeUser ? 'Past Time' : 'Your Subscription Will Expire In'}
          </Typography>
          <Stack direction="row" alignItems="flex-end" sx={{ position: 'relative' }}>
            <CircularProgressWithLabel
              {...(isFreeUser ? { value: registeredDay, total: registeredDay } : { value: restDay, total: totalDay })}
            />
            <Typography variant="caption" fontWeight={300} sx={{ position: 'absolute', right: '-26px' }}>
              Days
            </Typography>
          </Stack>
          <Button variant="outlined" sx={{ p: '4px 8px', lineHeight: 1.2 }} component={Link} to="/upgrade-service">
            {isFreeUser ? 'Upgrade' : 'Renew'}
          </Button>
        </Stack>

        <Stack flex={1} direction="column" alignItems="center" justifyContent="space-between" minHeight="145px">
          <Typography textAlign="center" fontSize="12px" p="0 25px" color="#000" lineHeight={1.2}>
            Email Reminders
          </Typography>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography color="#1A1A1A" variant="body2">
              Today
            </Typography>
            <Typography color="#FF5922" fontSize="25px" fontWeight={700}>
              {todayTimes}
            </Typography>
            <Typography fontSize="10px" fontWeight={300} color="#000">
              Times
            </Typography>
          </Stack>
          <Stack direction="row" fontSize="11px">
            <span style={{ color: ' rgba(0, 0, 0, 0.85)', marginRight: '18px' }}>Total</span>{' '}
            <span style={{ color: '#1A1A1A', marginRight: '2px' }}>{totalTimes}</span>{' '}
            <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: 300 }}>Times</span>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default AlertViews
