import { InputAdornment, CircularProgress } from '@mui/material'

const LoadingAdornment = (props: { loading: boolean }) => {
  const { loading } = props
  return loading ? (
    <InputAdornment position="start">
      <CircularProgress size={25} />
    </InputAdornment>
  ) : null
}

export default LoadingAdornment
