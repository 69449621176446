import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Button,
} from '@mui/material'
import styled from '@mui/styled-engine-sc'
import { useAsyncFn } from 'react-use'
import { isString, omit } from 'lodash-es'
import { useSnackbar } from 'notistack'
import MetaMaskImage from './images/meta-mask.png'
import ConinbaseWalletImage from './images/coninbase-wallet.png'
import WalletConnectImage from './images/wallet-connect.png'
import { useMetaMask, useRequest, useUserInfo } from '../../hooks'
import CopyToClipboardButton from '../CopyToClipboardButton'
import { LoadUserByAddressType, LoginResponse } from '../../types'
import { Apis } from '../../constant'
import useToken from '../../hooks/useToken'

export type WalletDrawerRef = {
  toggle: () => void
  open: () => void
  close: () => void
}

const ImageBox = styled('img')`
  width: 2.75rem;
  height: 2.75rem;
`

const useLoadUserByAddress = () => {
  const request = useRequest()
  return useAsyncFn(
    async (address: string) => {
      if (!address) return
      return request<LoadUserByAddressType>(`${Apis.LoadUserByAddress}?address=${address}`)
    },
    [request]
  )
}

const WalletDrawer = React.forwardRef<WalletDrawerRef>((_props, ref) => {
  const { enqueueSnackbar } = useSnackbar()
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = useCallback(() => {
    setOpenDrawer((value) => !value)
  }, [])

  const [openDialog, setOpenDialog] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      toggle: toggleDrawer,
      open: () => setOpenDrawer(true),
      close: () => setOpenDrawer(false),
    }),
    [toggleDrawer]
  )

  const [loading, setLoading] = useState(false)
  const request = useRequest()
  const { setUserInfo } = useUserInfo()
  const [, setToken] = useToken()
  const [, loadUserByAddress] = useLoadUserByAddress()
  const { requestAccounts, requestPersonalSign, jumpToApp, notInstalled } = useMetaMask()

  const handleRequestAccount = async () => {
    const address = await requestAccounts()

    const response = await loadUserByAddress(address)
    if (!response) return

    const signature = await requestPersonalSign(response.user.address, response.user.loginMessage)

    const loginResponse = await request<LoginResponse>(Apis.Login, {
      method: 'post',
      body: JSON.stringify({
        address: response.user.address,
        message: response.user.loginMessage,
        signature,
        chainId: 1,
      }),
    })

    if (loginResponse.code !== 200 && isString(loginResponse.result)) {
      enqueueSnackbar(loginResponse.result, { variant: 'error' })
    } else {
      setUserInfo(omit(loginResponse, ['token', 'code', 'message']))
      setToken(loginResponse.token)
      window.location.replace('/my-subscriptions')
    }
  }

  const handleClickMetaMask = async () => {
    if (notInstalled) {
      setOpenDialog(true)
      return
    }

    setLoading(true)
    await handleRequestAccount().finally(() => {
      setLoading(false)
    })
  }

  const handleAgree = () => {
    setOpenDialog(false)
    jumpToApp()
  }

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor="bottom"
        onClose={toggleDrawer}
        SlideProps={{
          style: { margin: '0 0.78125rem 1.46875rem', borderRadius: '0.625rem' },
        }}
      >
        <Box sx={{ width: '100%' }}>
          <List>
            <ListItem>
              <ListItemButton onClick={handleClickMetaMask} disabled={loading}>
                <ListItemIcon>
                  <ImageBox src={MetaMaskImage} alt="MetaMask" />
                </ListItemIcon>
                <ListItemText primary="MetaMask" />
                {loading && <CircularProgress />}
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton disabled>
                <ListItemIcon>
                  <ImageBox src={ConinbaseWalletImage} alt="Coninbase Wallet" />
                </ListItemIcon>
                <ListItemText primary="Coninbase Wallet" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton disabled>
                <ListItemIcon>
                  <ImageBox src={WalletConnectImage} alt="WalletConnect" />
                </ListItemIcon>
                <ListItemText primary="WalletConnect" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tips</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ marginBottom: '2rem' }}>
            You must visit this website in the browser of the MetaMask application. Click Agree button to open the
            MetaMask application.
          </DialogContentText>
          <CopyToClipboardButton content={window.location.toString()} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAgree} autoFocus variant="contained">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default WalletDrawer

export const WalletDrawerContext = React.createContext<React.RefObject<WalletDrawerRef>>({
  current: null,
})

export const WalletDrawerProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const ref = useRef<WalletDrawerRef>(null)
  return (
    <>
      <WalletDrawer ref={ref} />
      <WalletDrawerContext.Provider value={ref}>{children}</WalletDrawerContext.Provider>
    </>
  )
}
