import { useEventCallback } from '@mui/material'
import dayjs from 'dayjs'
import { isNil } from 'lodash-es'
import { useLayoutEffect, useState } from 'react'
import { useAsync, useAsyncFn } from 'react-use'
import { Apis } from '../../../constant'
import { useRequest, useUserInfo } from '../../../hooks'
import {
  AddMonitorParams,
  EmailAlertHistory,
  EmailRemindStatistics,
  GetCollectionList,
  GetMoitorList,
  GetMoitorListResponse,
  Property,
  Trait,
} from '../../../types'

export const useCollectionList = () => {
  const request = useRequest()
  const result = useAsync(async () => {
    return request<{ collectionList: GetCollectionList[] }>(Apis.GetCollectionList)
  }, [request])

  return {
    data: result.value?.code === 200 ? result.value.collectionList : [],
    loading: result.loading,
  }
}

export const usePropertyList = (collectionId: number) => {
  const request = useRequest()
  const state = useAsync(async () => {
    if (isNil(collectionId)) return
    return request<{ propertyList: Property[] }>(Apis.GetPropertyAndTraitList, {
      method: 'post',
      body: JSON.stringify({ collectionId }),
    })
  }, [request, collectionId])

  if (state.value?.code === 200) {
    return {
      data: state.value.propertyList || [],
      loading: state.loading,
    }
  }
  return {
    data: [],
    loading: state.loading,
  }
}

export const useTraitList = (propertyName: string, propertyList: Property[]): Trait[] => {
  if (!propertyName || !propertyList || !propertyList.length) return []

  const property = propertyList.find((property) => propertyName === property.name)
  if (!property) {
    return []
  }
  return property.traitList
}

export const useAddMonitor = () => {
  const request = useRequest()
  return useAsyncFn(
    async (params: AddMonitorParams) => {
      return request(Apis.AddMonitor, {
        method: 'post',
        body: JSON.stringify(params),
      })
    },
    [request]
  )
}

export const useDeleteMonitor = () => {
  const request = useRequest()
  return useAsyncFn(
    async (monitorId: number) => {
      return request(Apis.DeleteMonitor, {
        method: 'post',
        body: JSON.stringify({
          monitorId,
        }),
      })
    },
    [request]
  )
}

export const useMoitorList = () => {
  const request = useRequest()
  const [moitorList, setMoitorList] = useState<GetMoitorList[]>([])
  const [loading, setLoading] = useState(true)

  const refetch = useEventCallback((keyword = '') => {
    setLoading(true)

    const params = {
      pageNumber: 1,
      pageSize: 9999,
      param: keyword,
    }

    request<GetMoitorListResponse>(Apis.GetMoitorList, {
      method: 'post',
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.code === 200) {
          if (response.monitorList) {
            setMoitorList(response.monitorList)
          } else {
            setMoitorList([])
          }
        }
      })
      .finally(() => setLoading(false))
  })

  useLayoutEffect(() => {
    refetch()
  }, [refetch])

  return { data: moitorList, loading, refetch }
}

export const useEmailAlertHistory = () => {
  const [emailAlertHistory, setEmailAlertHistory] = useState<EmailAlertHistory[]>([])
  const [loading, setLoading] = useState(true)
  const request = useRequest()

  useLayoutEffect(() => {
    setLoading(true)
    request<{ data: EmailAlertHistory[] }>(Apis.EmailAlertHistory)
      .then((response) => {
        if (response.code === 200) {
          setEmailAlertHistory(response.data)
        }
      })
      .finally(() => setLoading(false))
  }, [request])
  return { data: emailAlertHistory, loading }
}

export const useDayNumber = () => {
  const { userInfo } = useUserInfo()
  if (!userInfo)
    return {
      registeredDay: 0,
      restDay: 0,
      total: 0,
    }

  const { createdAt, serviceExpireIn, serviceStartSince } = userInfo
  const registeredDate = dayjs(createdAt).startOf('day')
  const startDate = dayjs(serviceStartSince).startOf('day')
  const endDate = dayjs(serviceExpireIn).startOf('day')
  const today = dayjs().startOf('day')

  const registeredDay = createdAt ? today.diff(registeredDate, 'day') : 0
  const restDay = endDate ? endDate.diff(today, 'day') : 0
  const totalDay = endDate.diff(startDate, 'day') || 0

  return {
    registeredDay,
    restDay,
    totalDay,
  }
}

export const useEmailRemindStatistics = () => {
  const [emailRemindStatistics, setEmailRemindStatistics] = useState<EmailRemindStatistics>({
    todayTimes: 0,
    totalTimes: 0,
  })
  const request = useRequest()
  useLayoutEffect(() => {
    request<{ data: EmailRemindStatistics }>(Apis.EmailRemindStatistics).then((response) => {
      if (response.code === 200) {
        setEmailRemindStatistics(response.data)
      }
    })
  }, [request])
  return emailRemindStatistics
}
