import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { GetCollectionList } from '../../../../types'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import CustomLabel from '../../../../components/CustomLabel'
import LoadingAdornment from '../LoadingAdornment'

interface CollectionSelectProps {
  collectionListLoading: boolean
  collectionList: GetCollectionList[]
}

const CollectionSelect: React.FC<CollectionSelectProps> = (props) => {
  const { collectionListLoading, collectionList } = props

  const { control } = useFormContext<FormFieldValues>()

  return (
    <Controller
      name={FormField.Collection}
      control={control}
      rules={{ required: { value: true, message: 'Please select a collection' } }}
      render={({ field: { ref, ...otherFieldProps }, fieldState }) => (
        <FormControl fullWidth hiddenLabel error={Boolean(fieldState.error)}>
          <CustomLabel htmlFor="collections-select">Collections</CustomLabel>
          <Select
            sx={{ bgcolor: '#fff' }}
            inputProps={{ id: 'collections-select' }}
            disabled={collectionListLoading}
            startAdornment={<LoadingAdornment loading={collectionListLoading} />}
            inputRef={ref}
            {...otherFieldProps}
          >
            {collectionList.map((collection) => (
              <MenuItem key={collection.id} value={collection.id}>
                {collection.name}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default CollectionSelect
