import { Box, CircularProgress, circularProgressClasses, CircularProgressProps, Typography } from '@mui/material'

const getColor = (value: number, total: number) => {
  const ration = value / total
  if (ration > 0.6) {
    return '#00B578'
  } else if (ration > 0.3) {
    return '#E1D950'
  } else if (ration > 0.1) {
    return '#FF751F'
  }
  return '#FA5151'
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number; total?: number }) => {
  const { value, total = 100, size = '4.0625rem', sx, ...otherProps } = props
  const color = getColor(value, total)
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={size}
        value={100}
        sx={{
          color: '#D8D8D8',
          ...sx,
        }}
        {...otherProps}
      />
      <CircularProgress
        size={size}
        variant="determinate"
        value={Math.round((value / total) * 100)}
        sx={{
          color,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...sx,
        }}
        {...otherProps}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color={color} fontSize="1.0625rem" fontWeight={600}>
          {Math.round(value)}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
