import { Button, Container, Box, Alert } from '@mui/material'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import styled from '@mui/styled-engine-sc'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import Logo from '../../images/logo.png'
import FlipGodFontImage from '../../images/FlipGod.png'
import GoldCoinImage from './images/gold-coin.png'
import XImage from './images/x.png'
import DcImage from './images/dc.png'
import { Footer, Tabs } from '../../components'
import { Header } from '../../styles/layout'
import { useUserInfo, useWalletDrawer } from '../../hooks'

const Layout = (props: { children: React.ReactNode }) => {
  const { children } = props
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs />
      {children}
      <Footer />
    </Box>
  )
}

const LogoStyle = styled('img')`
  width: 3.5625rem;
  height: 3.5625rem;
  margin: 2.75rem auto 0;
`

const LogoFontStyle = styled('img')`
  margin: 1.65625rem auto 0;
  width: 12.9375rem;
  height: 3.125rem;
`

const Detail = styled('p')`
  font-weight: 600;
  text-align: center;
  word-spacing: 0.3em;
  font-family: 'Montserrat-SemiBoldItalic';
  font-style: italic;
  margin: 0;

  &:first-of-type {
    margin: 4.28125rem 0 1.03125rem;
  }
`

const ButtonStyle = styled(Button)`
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  margin: 3.84375rem 0 auto;
  width: 12.5rem;
`

const WhyStyle = styled('p')`
  margin: 0 0 2.59375rem;
  font-family: Montserrat-ExtraBold;
  font-size: 1.125rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.85);
`

const IntroduceBox = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 0.9375rem;
  font-weight: normal;
  line-height: 1.875rem;
  padding: 2.4375rem 0 3.125rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);

  &:last-of-type {
    border-bottom: none;
  }

  > img {
    width: 2.5rem;
    height: 2.5rem;
  }

  > p {
    margin: 1.25rem 0 0;
  }
`

const HomeBody = () => {
  const walletDrawer = useWalletDrawer()
  const { userInfo } = useUserInfo()
  const navigate = useNavigate()
  const handleClickStart = () => {
    if (userInfo) {
      navigate('/my-subscriptions')
      return
    }

    if (walletDrawer.current) {
      walletDrawer.current.open()
    }
  }

  return (
    <>
      <Alert
        color="warning"
        severity="warning"
        icon={<WarningAmberOutlinedIcon fontSize="inherit" sx={{ color: '#fff' }} />}
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          backgroundColor: '#000',
          borderTop: '1px solid rgba(255, 255, 255, 0.8)',
          borderRadius: '0',
          color: '#FFFFFF',
          fontSize: '12px',
          fontFamily: 'Montserrat-Regular',
        }}
      >
        FlipGod is not yet optimized for mobile.
      </Alert>
      <Header>
        <LogoStyle src={Logo} alt="Logo" />
        <LogoFontStyle src={FlipGodFontImage} alt="Flip God" />
        <Detail style={{ fontSize: '0.9375rem' }}>All the NFT flipping tools you need</Detail>
        <Detail style={{ fontSize: '0.8125rem' }}>Flip your NFTs like an expert</Detail>
        <ButtonStyle variant="contained" onClick={handleClickStart}>
          Get Started
        </ButtonStyle>
        <WhyStyle>Why FlipGod?</WhyStyle>
        <KeyboardDoubleArrowDownIcon style={{ marginBottom: '1.875rem' }} />
      </Header>
      <Container style={{ padding: '0 2.1875rem' }}>
        <WhyStyle style={{ margin: '3.21875rem 0 1.1875rem', color: '#000', textAlign: 'center' }}>
          Why FlipGod?
        </WhyStyle>
        <IntroduceBox>
          <img src={GoldCoinImage} alt="gold coin" />
          <p>With our rich experiences in successful NFT flipping, we know what is essential to NFT Flippers. </p>
        </IntroduceBox>
        <IntroduceBox>
          <img src={XImage} alt="x" />
          <p>
            Thanks to the great resources and tools from our top experts in Web3, all the common users can enjoy our
            FlipGod service to make profits without efforts.
          </p>
        </IntroduceBox>
        <IntroduceBox>
          <img src={DcImage} alt="dc" />
          <p>With a strong background in blockchain since 2013, we provide secured and reliable technical support.</p>
        </IntroduceBox>
      </Container>
    </>
  )
}

const Home = () => {
  const isHome = Boolean(useMatch('/'))
  return <Layout>{isHome ? <HomeBody /> : <Outlet />}</Layout>
}

export default Home
