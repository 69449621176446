import React from 'react'
import ReactDOM from 'react-dom/client'
import { isDesktop } from 'react-device-detect'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// TODO pc 端迁移之后需要删除
if (process.env.NODE_ENV === 'production' && isDesktop) {
  location.hostname = 'flipgod.xyz'
} else {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}
