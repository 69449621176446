import { Box, CircularProgress } from '@mui/material'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { useLocalStorage, useMount } from 'react-use'
import { WalletDrawerProvider } from './components'
import { FailedSyncParamsStorageKey } from './constant'
import { useListenerMetaMaskChange, usePaymentWorkflow, useToken } from './hooks'
import { SyncResultOfPaymentParams } from './types'

const Fallback = () => (
  <Box sx={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress />
  </Box>
)

/**
 * 需要用到 Router Context 的 Provider 需要在这里注册
 */
const RouterRoot = () => {
  useListenerMetaMaskChange()

  const run = usePaymentWorkflow()
  const [params] = useLocalStorage<SyncResultOfPaymentParams>(FailedSyncParamsStorageKey)
  const [token] = useToken()

  useMount(() => {
    if (params && params.paymentInfo.txId && token) {
      run(params)
    }
  })

  return (
    <Suspense fallback={<Fallback />}>
      <WalletDrawerProvider>
        <Outlet />
      </WalletDrawerProvider>
    </Suspense>
  )
}

export default RouterRoot
