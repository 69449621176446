import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  IconButton,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  CircularProgress,
} from '@mui/material'
import { isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { EllipsisTypography } from '../../../../components'
import { GetMoitorList } from '../../../../types'
import { useDeleteMonitor, useMoitorList } from '../../hooks'
import OpenseaLogo from '../../images/opensea.svg'
import LooksrareLogo from '../../images/looksrare.svg'
import X2y2Logo from '../../images/x2y2.svg'
import BlurLogo from '../../images/blur.png'

const ThStyle = {
  fontFamily: 'Montserrat-Medium',
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '18px',
  color: '#07B9B9',
  boxSizing: 'border-box',
}

const EllipsisTypographyStyle = styled(EllipsisTypography)({
  lineHeight: 1.8,
  fontFamily: 'Montserrat-Regular',
  fontSize: '12px',
})

const tableRowSx = { '&:last-child td, &:last-child th': { border: 0 } }

type TableDataProps = { data: GetMoitorList[]; loading: boolean; onDeleted?: () => void }

const TableData = (props: TableDataProps) => {
  const { data, loading, onDeleted } = props
  const [activeIndex, setActiveIndex] = useState<number>()
  const [position, setPosition] = useState(0)
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [deletingMonitorState, deleteMonitor] = useDeleteMonitor()
  const [shouldDeleteMonitorId, setShouldDeleteMonitorId] = useState<number>()
  const { enqueueSnackbar } = useSnackbar()

  const handleClickDoalogButton = () => {
    if (isUndefined(shouldDeleteMonitorId)) {
      setOpen(false)
      return
    }
    deleteMonitor(shouldDeleteMonitorId)
      .then((res) => {
        if (res.code === 200) {
          enqueueSnackbar('Successfully Deleted')
          onDeleted?.()
        }
      })
      .finally(() => {
        setOpen(false)
      })
  }

  const marketToIcon = (marketList: string) => {
    if (!marketList) return ''

    return marketList
      .trim()
      .split('|')
      .map((item) => {
        const market = item.trim().toLowerCase()
        switch (market) {
          case 'opensea':
            return <img src={OpenseaLogo} alt="opensea" key={OpenseaLogo} title={market} />
          case 'looksrare':
            return <img src={LooksrareLogo} alt="looksrare" key={LooksrareLogo} title={market} />
          case 'x2y2':
            return <img src={X2y2Logo} alt="x2y2" key={X2y2Logo} title={market} />
          case 'blur':
            return <img src={BlurLogo} alt="blur" key={BlurLogo} title={market} />
          default:
            return ''
        }
      })
  }

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="email-alert-history" sx={{ maxHeight: '300px', tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={ThStyle} align="center">
                Collection <br /> Market
              </TableCell>
              <TableCell sx={ThStyle} align="center">
                Property <br />
                Time
              </TableCell>
              <TableCell sx={ThStyle} align="center">
                Trait <br /> ETH
              </TableCell>
            </TableRow>
          </TableHead>
          <ClickAwayListener
            onClickAway={(evt) => {
              if (buttonRef.current && evt.composedPath().includes(buttonRef.current)) {
                return
              }
              setActiveIndex(undefined)
            }}
          >
            <TableBody sx={{ position: 'relative' }}>
              {loading || data.length <= 0 ? (
                <TableRow sx={tableRowSx}>
                  <TableCell colSpan={3}>
                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Typography lineHeight="40px" textAlign="center" fontSize="12px" color="text.secondary">
                        No records to dispay
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                data.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      ...tableRowSx,
                      ...(activeIndex === index ? { background: 'rgba(0, 0, 0, 0.3)' } : {}),
                    }}
                    onClick={(event) => {
                      if (index === activeIndex) {
                        setActiveIndex(undefined)
                        return
                      }
                      const { currentTarget } = event
                      const { top, height } = currentTarget.getBoundingClientRect()
                      // for center
                      setPosition(top + window.scrollY + height / 2 - 52 / 2)
                      setActiveIndex(index)
                      setShouldDeleteMonitorId(item.id)
                    }}
                  >
                    <TableCell align="center" sx={{ p: '10px' }}>
                      <EllipsisTypographyStyle>{item.collection}</EllipsisTypographyStyle>
                      <Typography
                        sx={{ '& img': { marginLeft: '4px', width: '20px' }, '& img:first-child': { marginLeft: 0 } }}
                      >
                        {marketToIcon(item.market)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <EllipsisTypographyStyle>{item.property}</EllipsisTypographyStyle>
                      <EllipsisTypographyStyle sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {/* TODO 接口没返回这个字段 */}
                        {/* {item.date} */}
                      </EllipsisTypographyStyle>
                    </TableCell>
                    <TableCell align="center">
                      <EllipsisTypographyStyle>{item.trait}</EllipsisTypographyStyle>
                      <EllipsisTypographyStyle sx={{ color: '#FF8F1F' }}>{item.eth.toFixed(2)}</EllipsisTypographyStyle>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </ClickAwayListener>
        </Table>
      </TableContainer>
      <IconButton
        size="large"
        aria-label="delete"
        ref={buttonRef}
        onClick={() => setOpen(true)}
        sx={{
          'position': 'absolute',
          'top': `${position}px`,
          'right': '24px',
          'display': 'none',
          'background': '#fff',
          'boxShadow': '0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
          '&:hover': {
            background: '#fff',
          },
          ...(isUndefined(activeIndex) ? {} : { display: 'inline-flex' }),
        }}
      >
        <DeleteIcon fontSize="inherit" sx={{ color: '#FA5151' }} />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setShouldDeleteMonitorId(undefined)
          setOpen(false)
        }}
      >
        <DialogTitle id="alert-dialog-title">Delete this alert?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you confirm to delete this alert, you will not receive email notifications.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            variant="outlined"
            color="error"
            autoFocus
            loading={deletingMonitorState.loading}
            onClick={handleClickDoalogButton}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const AddedAlertHistory = () => {
  const { data: moitorList, loading, refetch } = useMoitorList()

  return (
    <Box>
      <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', p: '0 10px', mb: '5px', height: '44px' }}>
        <SearchIcon fontSize="medium" sx={{ color: 'rgba(0, 0, 0, 0.3)' }} />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Property"
          inputProps={{ 'aria-label': 'search property' }}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              refetch(evt.currentTarget.value)
            }
          }}
        />
      </Paper>
      <Paper elevation={0}>
        <TableData data={moitorList} loading={loading} onDeleted={() => refetch()} />
      </Paper>
    </Box>
  )
}

export default AddedAlertHistory
