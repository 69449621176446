import { noop } from 'lodash-es'
import React from 'react'
import { useLocalStorage } from 'react-use'
import { TokenLocalStorageKey } from '../constant'

export type TokenContextValue = ReturnType<typeof useLocalStorage<string>>

export const TokenContext = React.createContext<TokenContextValue>([undefined, noop, noop])

export const TokenContextProvider = ({ children }: { children: React.ReactNode }) => {
  const tokenValues = useLocalStorage<string>(TokenLocalStorageKey, undefined, { raw: true })
  return <TokenContext.Provider value={tokenValues}>{children}</TokenContext.Provider>
}
