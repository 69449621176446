export const UserLocalStorageKey = '__flipgod__user__info__'

export const TokenLocalStorageKey = '__flipgod__token__'

export const FailedSyncParamsStorageKey = '__flipgod__failed__sync__params__'

export const TabsHeight = '4.125rem'

export enum Apis {
  LoadUserByAddress = '/users/load-user-by-address',
  Login = '/users/login',
  GetUserDetail = '/users/get-user-detail',
  EmailRemindStatistics = '/users/email-remind-statistics',
  EmailAlertHistory = '/users/email-alert-history',
  GetMoitorList = '/users/get-monitor-list',
  GetCollectionList = '/users/get-collection-list',
  GetPropertyAndTraitList = '/users/get-propertyAndTrait-list',
  DeleteMonitor = '/users/delete-monitor',
  AddMonitor = '/users/add-monitor',
  ServiceSubscription = '/users/service-subscription',
  VerifyEmail = '/users/verify-email',

  SyncResultOfPayment = '/bps/v1/purchase/ethereum/sync',
  GetOrderHistory = '/bps/v1/invoice/history',
}
