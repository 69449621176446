import Box from '@mui/material/Box'
import styled from '@mui/styled-engine-sc'
import { Footer, AppBar } from '../../components'
import DiscordImg from './discord.png'

// const TopBar = styled('div')`
//   height: 4.125rem;
//   background: #000;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-family: Montserrat-Medium;
//   color: #fff;
//   font-size: 1.125rem;
// `
const Content = styled('div')`
  background: #fff;
  padding: 0 0.375rem 3.75rem 0.5625rem;
`
const JoinUs = styled('div')`
  padding: 19px 9px 34px 4px;
`
const Top = styled('div')`
  display: flex;
  align-items: center;
  span {
    font-family: Montserrat-Regular;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0em;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 4px;
  }
  a {
    height: 16px;
  }
  img {
    width: 24px;
    height: 16px;
    font-color: #3e59dd;
    margin-left: 24px;
  }
`
const Divider = styled('div')`
  border: 1px dashed rgba(0, 0, 0, 0.3);
  margin: 20px 0 10px;
`
const Bottom = styled('div')`
  font-family: Montserrat-Medium;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 0.6);
  padding: 14px 17px 0 4px;

  span {
    font-weight: 500;
    color: #3e59dd;
  }
`
const QAList = styled('div')``
const Title = styled('div')`
  background: rgba(0, 181, 120, 0.1);
  border-radius: 5px;
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  padding: 7px 0 5px 4px;
  margin-bottom: 9px;
  box-sizing: border-box;
`
const IssueContent = styled('div')`
  padding-left: 4px;
  padding-bottom: 25px;
`
const Question = styled('div')`
  font-family: Montserrat-Regular;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0em;
  color: #00b578;
  margin-bottom: 13px;
`
const Answer = styled('div')`
  display: flex;
  margin-bottom: 15px;
`
const Spot = styled('div')`
  flex: none;
  margin: 9px 11px 0 0;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.85);
`
const Desc = styled('div')`
  flex: auto;
  font-family: Montserrat-Light;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
`
interface IssueContent {
  number: number
  question: string
  answer: string[]
}
interface IssueObj {
  issueType: string
  issueContent: IssueContent[]
}
const issueObj = [
  {
    issueType: 'Product Issue',
    issueContent: [
      {
        number: 1,
        question: 'Q1.What is Great Deal Alert?',
        answer: [
          'You can monitor the price of a whole collection or certains with any specific trait. When the item which meets your monitoring requirements has been listed with a price lower than your monitoring price, FlipGod will automaticly send an email alert to your email address. ',
          'You can also connect with our telegram bot on the "settings" page for prompt NFT flipping alerts.',
        ],
      },
    ],
  },
  {
    issueType: 'Payment Issue',
    issueContent: [
      {
        number: 1,
        question: 'Q1.Unable to pay?',
        answer: [
          'Please check if your wallet is connected',
          'Please check if your balance is enough to pay (including the gas fee)',
          'Please check if your network works normally',
        ],
      },
      {
        number: 2,
        question: "Q2.Why can't I have advanced service during my subscription?",
        answer: ['Please check if you are connected with the correct wallet address.'],
      },
      {
        number: 3,
        question: 'Q3.How can I renew my subscription?',
        answer: [
          'There is a "renew" button on each page of different services. Of course, you can also click in "upgrade service" to renew your subscription.',
        ],
      },
      {
        number: 4,
        question: "Q4.What's the difference between Premium membership and buying the full services at one time?",
        answer: [
          'In order to meet ever-changing customer needs for NFT flipping, we will continuously update our services. To be our Premium member, you can not only fully enjoy all our FlipGod services, but also get our additional new services for free.',
        ],
      },
    ],
  },
  {
    issueType: 'Technical Issue',
    issueContent: [
      {
        number: 1,
        question: 'Q1.Unable to enter the service page when I click the start button',
        answer: ['Please check if your network works normally', 'Please check if your wallet is connected'],
      },
      {
        number: 2,
        question: 'Q2.Unable to receive the confirmation email or email alert?',
        answer: [
          'Please check if your email address is correct',
          "Please check if they are in your spam folder. If yes, please move them to the inbox. It's highly recommended to connect your telegram account with our FlipGod notification bot in the settings, and then click the start button to have the prompt alert message.",
        ],
      },
      {
        number: 3,
        question: "Q3.Why I don't receive my Great Deal Alert emails?",
        answer: [
          `If you don't reveive your Great Deal Alert emails which you have set up for your NFT monitoring, please check your spam folder because they are probably treated as the spam eamils.
                In this case, please move them into the inbox in order to receive your Great Deal Alert emails in time. `,
        ],
      },
    ],
  },
]
const QAndA = () => {
  return (
    <Box sx={{ width: '100%' }}>
      {/* <TopBar>Q&A</TopBar> */}
      <AppBar title="Q&A" />
      <Content>
        <JoinUs>
          <Top>
            <span>Join our discord server for a prompt reply</span>
            <a href="https://discord.gg/FsPf4ynPjK" target="_blank" rel="noreferrer">
              <img src={DiscordImg} alt="discord" />
            </a>
          </Top>
          <Divider />
          <Bottom>
            Frequently asked questions are explained here. If you cannot find an answer, join our{' '}
            <span>Discord server</span> for a prompt reply.
          </Bottom>
        </JoinUs>
        {issueObj.map((issue: IssueObj) => {
          return (
            <QAList key={issue.issueType}>
              <Title>{issue.issueType}</Title>
              {issue.issueContent.map((q: IssueContent) => {
                return (
                  <IssueContent key={q.number}>
                    <Question>{q.question}</Question>
                    {q.answer.map((a, index: number) => {
                      return (
                        <Answer key={`${index + 1}`}>
                          {q.answer.length > 1 && <Spot />}
                          <Desc>{a}</Desc>
                        </Answer>
                      )
                    })}
                  </IssueContent>
                )
              })}
            </QAList>
          )
        })}
      </Content>
      <Footer />
    </Box>
  )
}

export default QAndA
