import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider } from '@mui/material'
import { lazy } from 'react'
import { Home, Tools } from './pages'
import { EthereumContextProvider, UserContextProvider } from './context'
import RouterRoot from './RouterRoot'

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    button: {
      textTransform: 'initial',
    },
  },
})

const MySubscriptions = lazy(() => import('./pages/MySubscriptions'))
const Settings = lazy(() => import('./pages/Settings'))
const Questions = lazy(() => import('./pages/Questions'))
const SetNewAlerts = lazy(() => import('./pages/MySubscriptions/SetNewAlerts'))
const UpgradeService = lazy(() => import('./pages/UpgradeService'))
const OrderHistory = lazy(() => import('./pages/OrderHistory'))
const NotFound = lazy(() => import('./pages/404'))

// TODO move to a file
const router = createBrowserRouter([
  {
    element: <RouterRoot />,
    children: [
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: 'tools',
            element: <Tools />,
          },
        ],
      },
      {
        path: '/my-subscriptions',
        element: <MySubscriptions />,
        children: [
          {
            path: 'set-new-alert',
            element: <SetNewAlerts />,
          },
        ],
      },
      {
        path: '/upgrade-service',
        element: <UpgradeService />,
      },
      {
        path: '/order-history',
        element: <OrderHistory />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
      {
        path: '/questions',
        element: <Questions />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
])

function App() {
  return (
    <ThemeProvider theme={theme}>
      <EthereumContextProvider>
        <UserContextProvider>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </UserContextProvider>
      </EthereumContextProvider>
    </ThemeProvider>
  )
}

export default App
