import { FormControl, MenuItem, Select, IconButton } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import { Property } from '../../../../types'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import CustomLabel from '../../../../components/CustomLabel'
import LoadingAdornment from '../LoadingAdornment'

interface PropertySelectProps {
  propertyListLoading: boolean
  propertyList: Property[]
}

const PropertySelect: React.FC<PropertySelectProps> = (props) => {
  const { propertyListLoading, propertyList } = props

  const { control, setValue } = useFormContext<FormFieldValues>()

  return (
    <FormControl fullWidth hiddenLabel>
      <CustomLabel htmlFor="property-select">Property</CustomLabel>
      <Controller
        name={FormField.Property}
        control={control}
        render={({ field: { ref, value, ...otherFieldProps } }) => (
          <Select
            sx={{
              'bgcolor': '#fff',
              '& .MuiSelect-iconOutlined': { display: value ? 'none' : undefined },
              '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
            }}
            inputProps={{ id: 'property-select' }}
            disabled={propertyListLoading}
            startAdornment={<LoadingAdornment loading={propertyListLoading} />}
            inputRef={ref}
            value={value}
            endAdornment={
              <IconButton
                sx={{ display: value ? undefined : 'none' }}
                onClick={() => {
                  setValue(FormField.Property, '')
                }}
              >
                <ClearIcon />
              </IconButton>
            }
            {...otherFieldProps}
          >
            {propertyList.map((property) => (
              <MenuItem key={property.id} value={property.name}>
                {property.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  )
}

export default PropertySelect
