import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material'
import { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Trait } from '../../../../types'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import CustomLabel from '../../../../components/CustomLabel'
import LoadingAdornment from '../LoadingAdornment'

interface TraitSelectProps {
  traitList: Trait[]
  propertyListLoading: boolean
  propertyName: string
}

const TraitSelect = (props: TraitSelectProps) => {
  const { traitList, propertyListLoading, propertyName } = props

  const { unregister, control } = useFormContext<FormFieldValues>()

  const {
    field: { ref: traitSelectRef, ...traitSelectOtherProps },
    fieldState: { error: traitSelectError },
  } = useController({
    name: FormField.Trait,
    control,
    rules: {
      validate: (value) => {
        if (propertyName && !value) {
          return 'Please select traits'
        }
        return true
      },
    },
  })

  useEffect(() => () => unregister(FormField.Trait), [unregister])

  return (
    <FormControl fullWidth hiddenLabel error={Boolean(traitSelectError)}>
      <CustomLabel htmlFor="trait-select">Trait</CustomLabel>
      <Select
        sx={{ bgcolor: '#fff' }}
        inputProps={{ id: 'trait-select' }}
        disabled={propertyListLoading || !propertyName}
        startAdornment={<LoadingAdornment loading={propertyListLoading} />}
        inputRef={traitSelectRef}
        {...traitSelectOtherProps}
      >
        {traitList.map((trait) => (
          <MenuItem key={trait.id} value={trait.name}>
            {trait.name}
          </MenuItem>
        ))}
      </Select>
      {traitSelectError && <FormHelperText>{traitSelectError.message}</FormHelperText>}
    </FormControl>
  )
}

export default TraitSelect
