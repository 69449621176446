import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'
import { useSnackbar } from 'notistack'
import useRequest from './useRequest'
import { SyncResultOfPaymentParams, SyncResultOfPaymentResponse } from '../types'
import { Apis, FailedSyncParamsStorageKey } from '../constant'
import { retry } from '../services'

const useHandlePaymentError = () => {
  const { enqueueSnackbar } = useSnackbar()

  const handleError = useCallback(() => {
    const message =
      'Oops, there are some issues on network or service, please refresh this page we will retry again, or contact our community for support.'
    enqueueSnackbar(message, {
      variant: 'error',
    })
    throw new Error(message)
  }, [enqueueSnackbar])

  return handleError
}

const useHandlePaymentSuccess = () => {
  const [, , cleanParams] = useLocalStorage(FailedSyncParamsStorageKey)

  const handleSuccess = useCallback(() => {
    cleanParams()
  }, [cleanParams])

  return handleSuccess
}

const useSyncResultOfPayment = () => {
  const request = useRequest()

  const syncResultOfPayment = useCallback(
    async (params: SyncResultOfPaymentParams) => {
      return request<SyncResultOfPaymentResponse>(`${Apis.SyncResultOfPayment}?minimumConfirms=${1}&waitingFor=${45}`, {
        method: 'post',
        body: JSON.stringify(params),
      })
    },
    [request]
  )

  return syncResultOfPayment
}

const useServiceSubscrpition = () => {
  const request = useRequest()

  const serviceSubscription = useCallback(
    async (invoiceId: string) => {
      return request(`${Apis.ServiceSubscription}?invoiceId=${invoiceId}`, {
        method: 'post',
      })
    },
    [request]
  )

  return serviceSubscription
}

const usePaymentWorkflow = () => {
  const sync = useSyncResultOfPayment()
  const service = useServiceSubscrpition()
  const handleError = useHandlePaymentError()
  const handleSuccess = useHandlePaymentSuccess()
  const [, setFailedSyncParams] = useLocalStorage(FailedSyncParamsStorageKey)

  const run = useCallback(
    (param: SyncResultOfPaymentParams) => {
      setFailedSyncParams(param)
      return retry(10, sync, param)
        .then((response) => retry(3, service, response._id))
        .then(() => handleSuccess())
        .catch(() => handleError())
    },
    [handleError, service, sync, handleSuccess, setFailedSyncParams]
  )

  return run
}

export default usePaymentWorkflow
