import { FormControl, Stack, OutlinedInput, Typography, FormHelperText } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Trait } from '../../../../types'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import CustomLabel from '../../../../components/CustomLabel'

interface MinMaxInputProps {
  traitList: Trait[]
}

const MinMaxInput = (props: MinMaxInputProps) => {
  const { traitList } = props
  const { unregister, control } = useFormContext<FormFieldValues>()

  const formControlRef = useRef<HTMLDivElement>(null)
  const formControlHeight = formControlRef.current
    ? `${formControlRef.current.getBoundingClientRect().height}px`
    : undefined

  const {
    field: { ref: minInputRef, ...minInputOtherProps },
    fieldState: { error: minInputError },
  } = useController({
    name: FormField.Min,
    control,
    rules: {
      required: {
        value: true,
        message: 'Please input this field',
      },
    },
  })

  const {
    field: { ref: maxInputRef, ...maxInputOtherProps },
    fieldState: { error: maxInputError },
  } = useController({
    name: FormField.Max,
    control,
    rules: {
      required: {
        value: true,
        message: 'Please input this field',
      },
    },
  })

  useEffect(() => () => unregister([FormField.Min, FormField.Max]), [unregister])

  return (
    <Stack direction="row" justifyContent="space-between" flexWrap="nowrap" alignItems="flex-end">
      <FormControl hiddenLabel error={Boolean(minInputError || maxInputError)} sx={{ flex: 1 }}>
        <CustomLabel htmlFor="min-input">Min & Max</CustomLabel>
        <OutlinedInput
          id="min-input"
          sx={{ bgcolor: '#fff' }}
          type="number"
          placeholder={traitList && traitList.length ? traitList[0].min?.toString() : undefined}
          inputProps={{ 'aria-label': 'min value' }}
          inputRef={minInputRef}
          {...minInputOtherProps}
        />
        {(minInputError || maxInputError) && (
          <FormHelperText>{minInputError?.message || maxInputError?.message}</FormHelperText>
        )}
      </FormControl>
      <Typography sx={{ width: '35px', textAlign: 'center', height: formControlHeight, lineHeight: formControlHeight }}>
        To
      </Typography>
      <FormControl hiddenLabel sx={{ flex: 1 }} ref={formControlRef}>
        <OutlinedInput
          sx={{ bgcolor: '#fff' }}
          type="number"
          placeholder={traitList && traitList.length ? traitList[0].max?.toString() : undefined}
          inputProps={{ 'aria-label': 'max value' }}
          inputRef={maxInputRef}
          {...maxInputOtherProps}
        />
      </FormControl>
    </Stack>
  )
}

export default MinMaxInput
