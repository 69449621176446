import { useEventCallback } from '@mui/material'
import { logout } from '../services'
import { CommonResponse } from '../types'
import useToken from './useToken'

const BASE_URL = process.env.NODE_ENV === 'development' ? '/api' : process.env.REACT_APP_API_TARGET

const getInput = (input: Parameters<typeof fetch>[0]): Parameters<typeof fetch>[0] => {
  if (typeof input === 'string') {
    return `${BASE_URL}${input.startsWith('/') ? '' : '/'}${input}`
  } else if (input instanceof Request) {
    const url = input.url
    return {
      ...input,
      url: `${BASE_URL}${url.startsWith('/') ? '' : '/'}${url}`,
    }
  }
  return input
}

type RequestInputType = Parameters<typeof fetch>[0]
type RequestInitType = Parameters<typeof fetch>[1]

const useRequest = () => {
  const [token] = useToken()

  return useEventCallback(
    async <T = Record<string, unknown>>(
      input: RequestInputType,
      init?: RequestInitType
    ): Promise<T & CommonResponse> => {
      try {
        return fetch(getInput(input), {
          headers: {
            'Content-Type': 'application/json',
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...init?.headers,
          },
          ...init,
        }).then((response) => {
          if (response.status === 401) {
            logout()
          }

          return response.json()
        })
      } catch (error) {
        return Promise.reject(error)
      }
    }
  )
}

export type UseRequestReturn = ReturnType<typeof useRequest>

export default useRequest
