import { Typography, TypographyProps } from '@mui/material'

const EllipsisTypography = (props: TypographyProps) => {
  const { children, sx, ...otherProps } = props
  return (
    <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...sx }} {...otherProps}>
      {children}
    </Typography>
  )
}

export default EllipsisTypography
