import { LoadingButton } from '@mui/lab'
import styled from 'styled-components'

export const YellowButton = styled(LoadingButton)({
  'backgroundColor': '#FF8F1F',
  'fontFamily': 'Montserrat-Medium',
  'fontSize': '17px',
  'fontWeight': 500,
  'padding': '9px 52px',
  'lineHeight': 1.3,
  '&:disabled': {
    background: '#E0E0E0',
  },
  '&:not([disabled]):hover': {
    backgroundColor: '#f5881c',
  },
  '@media (hover: none)': {
    '&:not([disabled]):hover': {
      backgroundColor: '#f5881c',
    },
  },
})
