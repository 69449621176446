import detectEthereumProvider from '@metamask/detect-provider'
import type { MetaMaskInpageProvider } from '@metamask/providers'
import React, { useLayoutEffect, useState } from 'react'

export type EthereumContextType = MetaMaskInpageProvider | null

export const EthereumContext = React.createContext<EthereumContextType>(null)

export const EthereumContextProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const [provider, setProvider] = useState<EthereumContextType>(null)

  useLayoutEffect(() => {
    detectEthereumProvider<MetaMaskInpageProvider>().then((value) => {
      setProvider(value)
    })
  }, [])

  return <EthereumContext.Provider value={provider}>{children}</EthereumContext.Provider>
}
