import { Box, Container, IconButton } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Link, Outlet, useMatch } from 'react-router-dom'
import { AppBar, Footer } from '../../components'
import { AddedAlertHistory, AlertHistory, AlertViews, ExpirationAlert } from './components'

const MySubscriptions = () => {
  const isRoot = Boolean(useMatch('/my-subscriptions'))

  return (
    <Box>
      {isRoot ? (
        <>
          <AppBar
            title="My Subscriptions"
            rightAction={
              <IconButton aria-label="set new alerts" color="inherit" component={Link} to="set-new-alert">
                <AddIcon />
              </IconButton>
            }
          />
          <ExpirationAlert />
          <Container
            sx={{
              bgcolor: '#EAEAEA',
              p: '13px 9px 100px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
            }}
          >
            <AlertViews />
            <AlertHistory />
            <AddedAlertHistory />
          </Container>
        </>
      ) : (
        <Outlet />
      )}
      <Footer />
    </Box>
  )
}

export default MySubscriptions
