export type CommonResponse = {
  code: number
  message: string
}

export type LoadUserByAddressType = CommonResponse & {
  user: {
    id: number
    address: string
    username: string
    emailStatus: boolean
    nonce: string
    loginMessage: string
    whetherRegistered: boolean
    avatarUrl: string
  }
}

export enum Role {
  ROLE_USER = 'ROLE_USER',
  ROLE_VIP = 'ROLE_VIP',
}

export type UserInfo = {
  address: string
  role: Role
  avatarUrl: string
  emailUpdateStatus: boolean
  emailStatus: boolean
  expireDate: string
  id: number
  telegramStatus: boolean
  email: string
  username: string
  result?: string
  serviceStartSince: ''
  serviceExpireIn: ''
  createdAt: ''
}

export type LoginResponse = CommonResponse & UserInfo & { token: string }

export type GetUserDetailResponse = CommonResponse & UserInfo

export type EmailRemindStatistics = {
  todayTimes: number
  totalTimes: number
}

export type EmailAlertHistory = {
  id: number
  collectionName: string
  createAt: string
  todayAlert: number
}

export type GetMoitorList = {
  id: number
  eth: number
  market: string
  trait: string
  property: string
  collection: string
}

export type GetMoitorListResponse = {
  totalSize: number
  monitorList: GetMoitorList[]
}

export type GetCollectionList = {
  id: number
  address: string
  name: string
  createTime: string
  slug: string
  totalSupply: string
}

export type Trait = {
  id: number
  collectionId: number
  propertyId: number
  name: string
  min: number | null
  max: number | null
  count: number
  createTime: string
}

export type Property = {
  id: number
  collectionId: number
  type: number
  name: string
  createTime: string
  traitList: Trait[]
}

export type AddMonitorParams = {
  collectionId: number
  price: number
  marketList: string[]
  propertyList: { traitType: number; traitValue?: string; min?: number; max?: number }[]
}

type Item = {
  name: string
  desc: string
  amount: number
  price: number
  info: {
    servingDays: number
    type: string
  }
}

type PaymentInfo = {
  txId: string
  chain: string
  currency: string
  unit: string
  amount?: string
  from: string
  to: string
}

export type SyncResultOfPaymentParams = {
  items: Item[]
  paymentInfo: PaymentInfo
  note: string
}

export type SyncResultOfPaymentResponse = {
  _id: string
}

export type OrderHistory = {
  _id: string
  items: Item[]
  paymentInfo: PaymentInfo
  note: string
  status: string
  totalAmountPaid: string
  tip: 0
  meta: { createdAt: string; updatedAt: string; deletedAt: string; version: number }
}

export type GetOrderHistoryResponse = {
  total: number
  records: OrderHistory[]
  timePeriod: string
  nextPage: { size: number; number: number }
}
