import { useRef } from 'react'
import Tab from '@mui/base/TabUnstyled'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Avatar, Box } from '@mui/material'
import SideDrawer from '../../../SideDrawer'
import { SideDrawerInstance } from '../../../SideDrawer/SideDrawer'
import { useUserInfo, useWalletDrawer } from '../../../../hooks'
import { ImperialCrown } from '../../../ImperialCrown'

const UserTab = () => {
  const { userInfo } = useUserInfo()
  const walletDrawer = useWalletDrawer()
  const sideDrawerRef = useRef<SideDrawerInstance>(null)

  if (userInfo) {
    return (
      <>
        <Box sx={{ width: '30px', height: '30px', mr: 'auto' }}>
          <ImperialCrown />
          <Tab
            value="value"
            component={Avatar}
            src={userInfo.avatarUrl}
            alt={userInfo.username}
            sx={{ width: '1.875rem', height: '1.875rem' }}
            onClick={() => {
              sideDrawerRef.current?.open()
            }}
          />
        </Box>
        <SideDrawer ref={sideDrawerRef} />
      </>
    )
  }
  return (
    <Tab
      value="value"
      style={{ marginRight: 'auto', fontSize: '1.875rem' }}
      component={AccountCircleOutlinedIcon}
      onClick={() => {
        if (walletDrawer.current) {
          walletDrawer.current.open()
        }
      }}
    />
  )
}

export default UserTab
