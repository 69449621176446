import {
  ListItemIcon,
  SwipeableDrawer,
  Typography,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import styled from '@mui/styled-engine-sc'
import React, { useImperativeHandle, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageIcon from '../ImageIcon'
import ServiceImage from './images/service.svg'
import ShopCarImage from './images/shop-car.svg'
import CustomerImage from './images/customer.svg'
import OrderImage from './images/order.svg'
import SettingImage from './images/setting.svg'
import LogoutImage from './images/logout.svg'
import { useUserInfo } from '../../hooks'
import { logout } from '../../services'
import { ImperialCrown } from '../ImperialCrown'

const ListItemStyle = styled(ListItem)`
  background: #fff;
  margin-top: 1.5625rem;
  border-radius: 0.3125rem;
  padding-top: 0;
  padding-bottom: 0;
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SideDrawerProps {}

export type SideDrawerInstance = {
  open: () => void
  close: () => void
}

const imageSize = '1.375rem'

const SideDrawer = React.forwardRef<SideDrawerInstance, SideDrawerProps>((_props, ref) => {
  const { userInfo } = useUserInfo()
  const [open, setOpen] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }),
    []
  )

  const handleClickLogout = () => {
    setOpen(false)
    logout()
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { bgcolor: '#F1F1F1' } }}
    >
      <Box sx={{ width: '19.6875rem', padding: '1.09375rem 1.09375rem 0', boxSizing: 'border-box' }}>
        <Stack columnGap={1} direction="row">
          <Box sx={{ width: '3.375rem', height: '3.375rem', position: 'relative' }}>
            <ImperialCrown sx={{ transform: 'scale(2)' }} />
            <Avatar src={userInfo?.avatarUrl} alt={userInfo?.username} />
          </Box>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{userInfo?.username}</Typography>
            <Typography color="rgba(0, 0, 0, .6)" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2">
              {userInfo?.address}
            </Typography>
          </Box>
        </Stack>
        <nav aria-label="side navigation bar">
          <List>
            <ListItemStyle>
              <ListItemButton component={Link} to="/my-subscriptions">
                <ListItemIcon>
                  <ImageIcon src={ServiceImage} alt="My Subscriptions" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="My Subscriptions" />
              </ListItemButton>
            </ListItemStyle>

            <ListItemStyle>
              <ListItemButton component={Link} to="/upgrade-service">
                <ListItemIcon>
                  <ImageIcon src={ShopCarImage} alt="Upgrade Service" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="Upgrade Service" />
              </ListItemButton>
            </ListItemStyle>

            <ListItemStyle>
              <ListItemButton component={Link} to="/questions">
                <ListItemIcon>
                  <ImageIcon src={CustomerImage} alt="Q&A" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="Q&A" />
              </ListItemButton>
            </ListItemStyle>

            <ListItemStyle>
              <ListItemButton component={Link} to="/order-history">
                <ListItemIcon>
                  <ImageIcon src={OrderImage} alt="Order" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="Order History" />
              </ListItemButton>
            </ListItemStyle>

            <ListItemStyle>
              <ListItemButton component={Link} to="/settings">
                <ListItemIcon>
                  <ImageIcon src={SettingImage} alt="Settings" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItemStyle>

            <ListItemStyle>
              <ListItemButton onClick={handleClickLogout}>
                <ListItemIcon>
                  <ImageIcon src={LogoutImage} alt="Log Out" size={imageSize} />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItemButton>
            </ListItemStyle>
          </List>
        </nav>
      </Box>
    </SwipeableDrawer>
  )
})

export default SideDrawer
