import { styled } from '@mui/material'
import React from 'react'

const Image = styled('img')({})

interface ImageIconProps {
  src: string
  alt: string
  size: string | number
  onClick?: React.MouseEventHandler<HTMLImageElement>
}

const ImageIcon: React.FC<ImageIconProps> = (props) => {
  const { src, alt, size: sizeProp, onClick } = props
  const size = typeof sizeProp === 'number' ? `${sizeProp}px` : sizeProp
  return <Image onClick={onClick} src={src} alt={alt} sx={{ width: size, height: size }} />
}

export default ImageIcon
