import { useLocalStorage } from 'react-use'
import { FailedSyncParamsStorageKey } from '../constant'

const useCanBuy = () => {
  const [params] = useLocalStorage(FailedSyncParamsStorageKey)

  return !params
}

export default useCanBuy
