import { isArray, isEmpty } from 'lodash-es'
import { useEffect } from 'react'
import useEthereumProvider from './useEthereumProvider'
import useUserInfo from './useUserInfo'

/**
 * 监听 MetaMask 的各种事件，并做出相应的处理
 * https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider
 */
const useListenerMetaMaskChange = () => {
  const provider = useEthereumProvider()
  const { userInfo, cleanUserInfo } = useUserInfo()
  const address = userInfo?.address

  useEffect(() => {
    if (!provider || !address) return

    provider.on('accountsChanged', (accounts) => {
      if (!isArray(accounts)) return

      // MetaMask is locked or the user has not connected any accounts
      if (isEmpty(accounts)) {
        cleanUserInfo()
        window.location.replace('/')
      }

      // The user has changed account address or checkout other account
      if (accounts[0] !== address) {
        cleanUserInfo()
        window.location.replace('/')
      }
    })

    provider.on('chainChanged', () => {
      window.location.reload()
    })
  }, [provider, address, cleanUserInfo])
}

export default useListenerMetaMaskChange
