import { Avatar, Stack, styled, Typography } from '@mui/material'
import { useRef } from 'react'
import { TabsHeight } from '../../constant'
import { useUserInfo } from '../../hooks'
import SideDrawer from '../SideDrawer'
import { SideDrawerInstance } from '../SideDrawer/SideDrawer'
import { ImperialCrown } from '../ImperialCrown'

const LeftActionBox = styled('div')({
  position: 'relative',
  width: '30px',
  height: '30px',
})

interface AppBarProps {
  title: string
  rightAction?: React.ReactNode
  leftAction?: React.ReactNode
}

const AppBar = (props: AppBarProps) => {
  const { title, rightAction, leftAction } = props
  const { userInfo } = useUserInfo()
  const sideDrawerRef = useRef<SideDrawerInstance>(null)

  return (
    <Stack
      sx={{ width: '100%', height: TabsHeight, bgcolor: '#000', boxSizing: 'border-box', color: '#fff' }}
      padding="0 1.5625rem"
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <LeftActionBox>
        {leftAction || (
          <>
            <ImperialCrown />
            <Avatar
              src={userInfo?.avatarUrl}
              alt={userInfo?.username}
              sx={{ width: '100%', height: '100%' }}
              onClick={() => {
                sideDrawerRef.current?.open()
              }}
            />
          </>
        )}
      </LeftActionBox>
      <Typography variant="h1" fontSize="1rem" fontWeight={500} fontFamily="Montserrat-Medium">
        {title}
      </Typography>
      <div>{rightAction}</div>
      <SideDrawer ref={sideDrawerRef} />
    </Stack>
  )
}

export default AppBar
