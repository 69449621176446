import { Alert, alertClasses, Button, AlertTitle } from '@mui/material'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useUserInfo } from '../../../../hooks'
import { Role } from '../../../../types'

const ExpirationAlert = () => {
  const { userInfo } = useUserInfo()

  const node = (
    <Alert
      severity="warning"
      sx={{
        fontSize: '12px',
        [`.${alertClasses.action}`]: {
          alignItems: 'center',
          pt: 0,
        },
      }}
      action={
        <Button color="warning" variant="contained" component={Link} to="/upgrade-service">
          Renew
        </Button>
      }
    >
      <AlertTitle sx={{ fontSize: '14px' }}>Your subscription will expire</AlertTitle>
      All the alert settings will be cleared when your subscription expires.
    </Alert>
  )

  if (userInfo) {
    const { role, serviceExpireIn, serviceStartSince } = userInfo
    if (role !== Role.ROLE_USER || !serviceExpireIn || !serviceStartSince) return null

    /**
     * 会员时长天数
     */
    const gap = dayjs(serviceExpireIn).startOf('day').diff(dayjs(serviceStartSince).startOf('day'), 'day')

    /**
     * 剩余天数
     */
    const rest = dayjs(serviceExpireIn).startOf('day').diff(dayjs().startOf('day'), 'day')

    /**
     * 360 天以上会员时长的用户，只有在剩余 10% 的期限内出现警告
     */
    if (gap >= 360 && rest <= Math.floor(gap * 0.1)) {
      return node
    }

    /**
     * 30 天以上 360 天以内会员时长的用户，只有在剩余 20% 的期限内出现警告
     */
    if (gap >= 30 && rest <= Math.floor(gap * 0.2)) {
      return node
    }
  }
  return null
}

export default ExpirationAlert
