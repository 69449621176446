import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@mui/material'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { FormField, FormFieldValues } from '../../SetNewAlerts'
import OpenseaLogo from '../../images/opensea.svg'
import LooksrareLogo from '../../images/looksrare.svg'
import X2y2Logo from '../../images/x2y2.svg'
import BlurLogo from '../../images/blur.png'

const options = [
  {
    icon: OpenseaLogo,
    label: 'OPENSEA',
    value: '1',
  },
  {
    icon: LooksrareLogo,
    label: 'LOOKSRARE',
    value: '2',
  },
  {
    icon: X2y2Logo,
    label: 'X2Y2',
    value: '3',
  },
  {
    icon: BlurLogo,
    label: 'BLUR',
    value: '4',
  },
]

const name = FormField.Marketplaces
const defaultValue = options.map((option) => option.value)

const Marketplaces = () => {
  const { control } = useFormContext<FormFieldValues>()
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    control,
    rules: { required: { value: true, message: 'Please select at least one marketplace' } },
  })
  const checkboxValues = useWatch({ control, name, defaultValue })

  const handleChange = (changedValue: string) => {
    const newCheckboxValues = [...checkboxValues]

    if (newCheckboxValues.length > 0) {
      const index = newCheckboxValues.findIndex((x) => x === changedValue)

      if (index === -1) {
        newCheckboxValues.push(changedValue)
      } else {
        newCheckboxValues.splice(index, 1)
      }
    } else {
      newCheckboxValues.push(changedValue)
    }

    onChange(newCheckboxValues)
  }

  return (
    <FormControl component="fieldset" variant="standard" error={Boolean(error)}>
      <FormLabel sx={{ ml: '11px', mb: '11px', color: '#00B578' }} component="legend">
        Marketplaces
      </FormLabel>
      <FormGroup sx={{ bgcolor: '#fff', borderRadius: '10px', p: '20px' }}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            aria-describedby={error && 'marketplace-helper-text'}
            sx={{
              'justifyContent': 'space-between',
              '& .MuiFormControlLabel-label': {
                display: 'inline-flex',
                alignItems: 'center',
                columnGap: '14px',
              },
            }}
            labelPlacement="start"
            label={
              <>
                {option.icon && <img src={option.icon} alt="opensea" width={20} />}
                {option.label}
              </>
            }
            control={
              <Checkbox
                inputRef={ref}
                onChange={() => handleChange(option.value)}
                checked={(value as string[] | undefined)?.some((checked) => checked === option.value)}
                {...inputProps}
              />
            }
          />
        ))}
      </FormGroup>
      {error && <FormHelperText id="marketplace-helper-text">{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default Marketplaces
